import { useState } from 'react';
import Button from '../../../../components/Button/Button';
import FormHeading from '../../../../components/Typography/FormHeading';
import { DEAL_STATUS_CAR_DELIVERY, DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS, DEAL_STEPS } from '../../../../constants';
import CarDelivery from '../CommonBankSteps/CarDelivery';
import LoaderInline from '../../../../components/Loader/LoaderInline';
import ContractSign from '../CommonBankSteps/ContractSign';
import CarRegistration from '../CommonBankSteps/CarRegistration';
import carRegistration from '../../../../api/cars/registration';
import CheckBankStatus from '../CommonBankSteps/CheckBankStatus';
import setStep from '../../../../api/deals/setStep';

const EurazFlow = ({ step, deal, goBack, update, documents, cars, bankId }) => {
  const [loading, setLoading] = useState(false);

  const onRegistrationSubmit = async (values) => {
    try {
      setLoading(true);
    await carRegistration({
      bankId,
      chunkId: deal?.chunkId,
      carId: cars?.[0]?.id,
      reg_num: '',
    });
    await setStep({ dealId: deal?.id, step: DEAL_STEPS.EURAZ.CAR_DELIVERY });
    update && update();
    } catch(e) {
      alert.error(e);
      setLoading(false);
    }
  };

  if (loading) {
    return <LoaderInline />;
  }

  if(step === DEAL_STEPS.EURAZ.SIGN_CONTRACT) {
    return <ContractSign deal={deal} documents={documents} cars={cars} dealStepAfterSign={DEAL_STEPS.EURAZ.AUTO_REGISTRATION} setLoading={setLoading} update={update}/>;
  }
  if (step === DEAL_STEPS.EURAZ.AUTO_REGISTRATION) {
    return (
      <>
        <CarRegistration
          onSuccess={onRegistrationSubmit}
          deal={deal}
          setLoading={setLoading}
          loading={loading}
          statusOption={[
            {
              value: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
              label: 'Ожидает постановки ТС на учет',
            },
            {
              value: DEAL_STATUS_CAR_DELIVERY,
              label: 'Машина поставлена на учет',
            },
          ]}
        />
        <div style={{marginTop: 20}}>
            <CheckBankStatus bankId={bankId} chunkId={deal?.chunkId} onSuccess={update} setLoading={setLoading} />
        </div>
      </>
    );
  }

  if (step === DEAL_STEPS.EURAZ.CAR_DELIVERY) {
    <CarDelivery deal={deal} loading={loading} setLoading={setLoading} onSuccess={update}/>;
  }
};

export default EurazFlow;
