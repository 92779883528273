import React, { useEffect, useState } from 'react';
import styles from './deal.module.css';
import DealCreationSteps from './DealCreationSteps/DealCreationSteps';
import Header from '../../../components/Header/Header';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Button from '../../../components/Button/Button';

import leads from '../../../assets/sidebar/deals.svg';
import DealCreationMainInfo from './DealCreateMainInfo';
import DealCreateEquipment from './DealCreateEquipment';
import DealCreateServices from './DealCreateServices';
import DealCreateDocs from './DealCreateDocs';
import DealCreateOther from './DealCreateOther';
import Alert from '../../../components/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import addItemsToDeal from '../../../api/deals/addItemsToDeal';
import getDealCars from '../../../api/deals/getDealCars';
import formatNumber from '../../../utils/formatNumber';
import { textStyles } from '../../../constants/textStyles';
import addDealComment from '../../../api/deals/addDealComment';
import setStep from '../../../api/deals/setStep';
import LoaderInline from '../../../components/Loader/LoaderInline';
import DealCreateCreditAppeal from './DealCreateCreditAppeal';
import BCCFlow from "./BCCFlow";

import {
  BANKS,
  DEAL_STATUS_DOCUMENTS_PROCESSING,
  DEAL_STATUS_WAITING_CLIENT_BANK_DOSCAR_AGREEMENT,
  DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
  DEAL_STATUS_WAITING_FOR_PAYMENT,
  DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
  DEAL_STEPS,
  DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
  DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES,
  DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
  DOCUMENT_TYPES_CONTRACT,
  DOCUMENT_TYPES_GUARANTEE_LETTER,
  DOCUMENT_TYPES_INVOICE,
  DOCUMENT_TYPES_POLES,
  DOCUMENT_TYPES_POWER_OF_ATTORNEY,
  DOCUMENT_TYPES_SPOUSE_CONSENT,
  DOCUMENT_TYPES_TECH_PASSPORT,
  PAYMENT_STATUS_PAID,
  PAYMENT_TYPES_CREDIT_CARD,
  PAYMENT_TYPES_CREDIT_INVOICE,
  PAYMENT_TYPES_PREPAYMENT_CARD,
  PAYMENT_TYPES_PREPAYMENT_INVOICE,
} from '../../../constants';
import uploadDocumentToDeal from '../../../api/deals/uploadDocumentToDeal';
import createCreditAppeal from '../../../api/creditPlatform/createCreditAppeal';
import DealCreateBanks from './DealCreateBanks';
import DealCreateBanksPay from './DealCreateBanksPay';
import generateUserAgreement from '../../../api/creditPlatform/generateUserAgreement';
import DealCreatePayment from './DealCreatePayment';
import editDeal from '../../../api/deals/editDeal';
import removeItemsToDeal from '../../../api/deals/removeItemsToDeal';
import DealCreateCreditDocuments from './DealCreateCreditDocuments';
import DealCreatePledgeRegistration from './DealCreatePledgeRegistration';
import generateAllowedDocumentsJson from '../../../utils/generateAllowedDocumentsJson';
import getCreditAppeal from '../../../api/deals/getCreditAppeal';
import HalykFlow from './HalykFlow';
import FreedomFlow from './FreedomFlow';
import extractErrorMessage from '../../../utils/extractErrorMessage';
import ForteFlow from './ForteFlow';
import ClientPaymentApprove from './ClientPaymentApprove';
import toastAlert from '../../../helpers/alert';
import BerekeFlow from './BerekeFlow';
import ClientRegistrationApprove from './ClientRegistrationApprove';
import AltynFlow from './AltynFlow';
import EurazFlow from './EurazFlow';
const DealCreate = ({ deal: savedDeal, cars, equipments, services, update, documents, loading, setLoading }) => {
  const [state, setState] = useState({});
  const [sum, setSum] = useState(0);
  const [currentStep, setCurrentStep] = useState(savedDeal?.step || 1);
  const [succes, setSucces] = useState({});

  const [deal, setDeal] = useState(savedDeal);
  const [orders, setOrders] = useState([]);
  const [unsavedEquipments, setUnsavedEquipments] = useState([]);
  const [unsavedServices, setUnsavedServices] = useState([]);
  const [agreementModal, setAgreementModal] = useState(false);
  const [chosenBankId, setChosenBankId] = useState(null);
  const [chosenBankAppealId, setChosenBankAppealId] = useState(null);
  const [chosenBankAppeal, setChosenBankAppeal] = useState(null);
  const [creditAppeals, setCreditAppeals] = useState([]);

  const isCreditDeal =
    deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_CARD || deal?.paymentType?.id === PAYMENT_TYPES_CREDIT_INVOICE;

  useEffect(() => {
    if (savedDeal?.id) {
      getDealCars({ id: savedDeal?.id }).then((res) => {
        const options = res?.data?.cars?.map((item) => {
          return {
            label: `${item.mark} ${item.model} (VIN ${item?.vin}, ${item?.complectation?.title}, ${
              item?.complectation?.engine?.title
            }, ${formatNumber(item?.container?.toPrice)} ₸)`,
            value: item?.orderId,
          };
        });

        setOrders(options);
      });

      getCreditAppeal({ chunkId: savedDeal?.chunkId }).then((res) => {
        const chosenBank = res?.data?.appeals?.find((appeal) => appeal?.selected);
        setChosenBankId(chosenBank?.bank?.id);
        setChosenBankAppealId(chosenBank?.id);
        setChosenBankAppeal(chosenBank);
        setCreditAppeals(res?.data?.appeals);
      });
    }
    setDeal(savedDeal);
  }, [savedDeal]);

  const onSubmitStep1 = () => {
    update && update();
  };
  const onEquipmentSubmit = (equipments, orderId) => {
    setLoading(true);
    addItemsToDeal({
      cars: [{ orderId: String(orderId), value: String(orderId) }],
      dealId: String(deal?.id),
      equipments,
      step: 3,
    })
      .then(() => {
        setUnsavedEquipments([]);
        update();
        setCurrentStep(3);
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  };
  const onSubmitStep3 = (services, orderId, step = 4) => {
    setLoading(true);
    addItemsToDeal({
      cars: [{ orderId: String(orderId), value: String(orderId) }],
      dealId: String(savedDeal?.id),
      services,
    })
      .then(async () => {
        setUnsavedServices([]);
        await editDeal({
          dealId: savedDeal?.id,
          id: savedDeal?.id,
          statusId: DEAL_STATUS_WAITING_FOR_PAYMENT,
          paymentTypeId: savedDeal?.paymentType?.id,
          equipmentStepPassed: true,
          ...generateAllowedDocumentsJson(
            savedDeal?.paymentType?.id == PAYMENT_TYPES_PREPAYMENT_INVOICE
              ? [DOCUMENT_TYPES_INVOICE]
              : [DOCUMENT_TYPES_TECH_PASSPORT]
          ),
        });
        setStep({ dealId: savedDeal?.id, step }).then(() => {
          update();
        });
      })
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  };
  const onSubmitStep4 = () => {
    update();
  };

  const [sendFormLoading, setSendFormLoading] = useState(false);
  const handleCreateCreditSubmit = (values, documents, files, showError = false) => {
    try {
      documents.map((doc) => uploadDocumentToDeal(doc));
      setSendFormLoading(true);
      return createCreditAppeal(
        {
          ...values,
          MaritalStatus: values?.MaritalStatus?.value,
        },
        files
      )
        .then(() => {
          setStep({ dealId: deal?.id, step: 5 }).then(() => {
            update();
          });
        })
        .catch((err) => {
          setAgreementModal(true);
          toastAlert.error(extractErrorMessage(err?.response?.data) || err?.response?.data || 'Ошибка при создании заявки на кредит');
        })
        .finally(() => {
          setSendFormLoading(false);
        });
    } catch (e) {
      console.log(e);
      setSendFormLoading(false);
    }
  };

  const onSubmitStep5 = (values) => {
    addDealComment({
      managerId: String(values?.managerId?.value),
      dealId: String(savedDeal?.id),
      comment: values?.comment,
    }).then(() => {
      setStep({ dealId: savedDeal?.id, step: 10 }).then(() => {
        update();
      });
    });
  };

  const onBankSelect = (bankId) => {
    console.log('BANKID', bankId);
    //check bank id and move to the flow of this bank
    if (bankId === BANKS.HALYK) {
      return editDeal({
        dealId: savedDeal?.id,
        id: savedDeal?.id,
        statusId: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
        ...generateAllowedDocumentsJson([DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT, DOCUMENT_TYPES_GUARANTEE_LETTER]),
      }).then(() => {
        setStep({ dealId: savedDeal?.id, step: DEAL_STEPS.HALYK.SIGN_CONTRACT }).then(() => {
          update();
        });
      });
    }
    if (bankId === BANKS.FREEDOM) {
      return editDeal({
        dealId: savedDeal?.id,
        id: savedDeal?.id,
        ...generateAllowedDocumentsJson([DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES, DOCUMENT_TYPES_GUARANTEE_LETTER]),
      }).then(() => {
        setStep({ dealId: savedDeal?.id, step: DEAL_STEPS.FREEDOM.WAITING_FOR_CLIENT_ACTIONS_ON_FREEDOM_SITE }).then(
          () => {
            update();
          }
        );
      });
    }
    if (bankId === BANKS.FORTE) {
      return editDeal({
        dealId: savedDeal?.id,
        id: savedDeal?.id,
        statusId: DEAL_STATUS_WAITING_FOR_NOTARIAL_DOCUMENTS,
        ...generateAllowedDocumentsJson([DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES, DOCUMENT_TYPES_GUARANTEE_LETTER]),
      }).then(() => {
        setStep({ dealId: savedDeal?.id, step: DEAL_STEPS.FORTE.SIGN_CONTRACT }).then(
          () => {
            update();
          }
        );
      });
    }

    if (bankId === BANKS.BEREKE) {
      return editDeal({
        dealId: savedDeal?.id,
        id: savedDeal?.id,
        ...generateAllowedDocumentsJson([DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES, DOCUMENT_TYPES_GUARANTEE_LETTER]),
      }).then(() => {
        setStep({ dealId: savedDeal?.id, step: DEAL_STEPS.BEREKE.SIGN_CONTRACT }).then(
          () => {
            update();
          }
        );
      });
    }
    if (bankId === BANKS.BCK) {
      return editDeal({
        dealId: savedDeal?.id,
        id: savedDeal?.id,
        ...generateAllowedDocumentsJson([DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES, DOCUMENT_TYPES_GUARANTEE_LETTER]),
      }).then(() => {
        setStep({ dealId: savedDeal?.id, step: DEAL_STEPS.BCK.SIGN_CONTRACT }).then(
          () => {
            update();
          }
        );
      });
    }
    if (bankId === BANKS.ALTYN) {
      return editDeal({
        dealId: savedDeal?.id,
        id: savedDeal?.id,
        ...generateAllowedDocumentsJson([DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES, DOCUMENT_TYPES_GUARANTEE_LETTER]),
      }).then(() => {
        setStep({ dealId: savedDeal?.id, step: DEAL_STEPS.ALTYN.SIGN_CONTRACT }).then(
          () => {
            update();
          }
        );
      });
    }
    if (bankId === BANKS.EURAZ) {
      return editDeal({
        dealId: savedDeal?.id,
        id: savedDeal?.id,
        ...generateAllowedDocumentsJson([DOCUMENT_TYPES_CERTIFICATE_OF_CIRCUMSTANCES, DOCUMENT_TYPES_GUARANTEE_LETTER]),
      }).then(() => {
        setStep({ dealId: savedDeal?.id, step: DEAL_STEPS.EURAZ.SIGN_CONTRACT }).then(
          () => {
            update();
          }
        );
      });
    }

    editDeal({
      dealId: savedDeal?.id,
      id: savedDeal?.id,
      statusId: DEAL_STATUS_WAITING_CLIENT_BANK_DOSCAR_AGREEMENT,
      ...generateAllowedDocumentsJson([DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT, DOCUMENT_TYPES_GUARANTEE_LETTER]),
    }).then(() => {
      setStep({ dealId: savedDeal?.id, step: 6 }).then(() => {
        update();
      });
    });
  };

  const dealComplete = () => {
    setStep({ dealId: savedDeal?.id, step: 10 }).then(() => {
      update();
    });
  };

  const onClientPaymentApproveSubmit = async () => {
    setLoading(true);
    try {
      let newStep = 6;

      if (chosenBankId === BANKS.HALYK) {
        newStep = DEAL_STEPS.HALYK.NOTARIAL_VERIFICATION;
      }
      if (chosenBankId === BANKS.FREEDOM) {
        newStep = DEAL_STEPS.FREEDOM.AUTO_REGISTRATION;
      }
      if (chosenBankId === BANKS.BCK) {
        newStep = DEAL_STEPS.BCK.NOTARIAL_VERIFICATION;
      }
      if (chosenBankId === BANKS.FORTE) {
        newStep = DEAL_STEPS.FORTE.NOTARIAL_VERIFICATION;
      }

      await editDeal({
        id: deal?.id,
        statusPaymentId: PAYMENT_STATUS_PAID,
        statusId: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
          DOCUMENT_TYPES_POWER_OF_ATTORNEY,
          DOCUMENT_TYPES_SPOUSE_CONSENT,
          DOCUMENT_TYPES_GUARANTEE_LETTER,
        ]),
      });
      await setStep({ dealId: savedDeal?.id, step: newStep });
      update();
    } catch (e) {
      toastAlert.error('Не удалось сменить статус сделки');
    }
  };
  const onClientRegistrationApproveSubmit = async () => {
    setLoading(true);
    try {
      let newStep = 6;

      if (chosenBankId === BANKS.HALYK) {
        newStep = DEAL_STEPS.HALYK.CAR_DELIVERY;
      }
      if (chosenBankId === BANKS.FREEDOM) {
        newStep = DEAL_STEPS.FREEDOM.WAITING_FOR_FINAL_ACTIONS;
      }
      if (chosenBankId === BANKS.BCK) {
        newStep = DEAL_STEPS.BCK.CAR_DELIVERY;
      }
      if (chosenBankId === BANKS.FORTE) {
        newStep = DEAL_STEPS.FORTE.WAITING_FOR_CLIENT_ACTIONS_ON_FORTE_MOBILE_APP;
      }

      await editDeal({
        id: deal?.id,
        statusPaymentId: PAYMENT_STATUS_PAID,
        statusId: DEAL_STATUS_WAITING_FOR_REGISTRATION_DOCUMENTS,
        ...generateAllowedDocumentsJson([
          DOCUMENT_TYPES_CLIENT_BANK_DOSCAR_AGREEMENT,
          DOCUMENT_TYPES_INVOICE,
          DOCUMENT_TYPES_APPLICATION_FOR_ADDITIONAL_SERVICES,
          DOCUMENT_TYPES_POWER_OF_ATTORNEY,
          DOCUMENT_TYPES_SPOUSE_CONSENT,
          DOCUMENT_TYPES_GUARANTEE_LETTER,
        ]),
      });
      await setStep({ dealId: savedDeal?.id, step: newStep });
      update();
    } catch (e) {
      toastAlert.error('Не удалось сменить статус сделки');
    }
  };

  const backHandler = (e, step) => {
    e.preventDefault();
    // setCurrentStep(currentStep - 1);
    setStep({ dealId: deal?.id, step: step || (currentStep - 1) }).then(() => {
      update && update();
    });
  };

  const removeEquipment = (id) => {
    setLoading(true);
    removeItemsToDeal({
      equipments: [{ value: id }],
      dealId: deal?.id,
    })
      .then(() => {
        update();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const removeService = (id) => {
    setLoading(true);
    removeItemsToDeal({
      services: [{ value: id }],
      dealId: deal?.id,
    })
      .then(() => {
        update();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const sum = savedDeal?.sum || 0;
    const sum2 =
      unsavedEquipments?.reduce((acc, item) => {
        return acc + item?.price * item?.n;
      }, 0) || 0;
    const sum3 =
      unsavedServices?.reduce((acc, item) => {
        return acc + item?.price;
      }, 0) || 0;
    setSum(sum + sum2 + sum3);
  }, [savedDeal, cars, equipments, unsavedEquipments, unsavedServices]);

  useEffect(() => {
    setCurrentStep(savedDeal?.step || 1);
  }, [savedDeal]);

  const updateCurrentStep = (step) => {
    setStep({ dealId: savedDeal?.id, step }).then(() => {
      update();
    });
  };

  const back = (step) => {
    return (
      <Button
        text={'Назад'}
        disabled={loading}
        onClick={(e) => backHandler(e, step)}
        style={{ color: '#BABABA', border: '1px solid #BABABA', width: '140px' }}
      />
    );
  };

  return (
    <>
      <Header title={'Новая сделка'} icon={leads} />
      <Breadcrumbs navigate={'/deals'} link={'Сделки'} currentPage={'Новая сделка'} />
      <div className={styles.DealCreate}>
        <div className={styles.DealCreateForm} id="DealCreateForm">
          {loading ? (
            <LoaderInline />
          ) : (
            <>
              {currentStep === 1 && (
                <DealCreationMainInfo nextStep={onSubmitStep1} deal={deal} cars={cars} update={update} />
              )}
              {currentStep === 2 && (
                <DealCreateEquipment
                  deal={deal}
                  equipments={equipments}
                  setUnsavedEquipments={setUnsavedEquipments}
                  orders={orders}
                  onSubmit={onEquipmentSubmit}
                  update={update}
                  dealCars={cars}
                  backHandler={back}
                />
              )}
              {(currentStep === 3 || currentStep === 311) && (
                <DealCreateServices
                  onSubmit={(services, orderId) => {
                    onSubmitStep3(services, orderId, currentStep === 3 ? 311 : 4)
                  }}
                  paymentType={savedDeal?.paymentType?.id}
                  deal={deal}
                  services={services}
                  orders={orders}
                  update={update}
                  setUnsavedServices={setUnsavedServices}
                  setLoading={setLoading}
                  backHandler={() => back(currentStep === 311 ? 3 : null)}
                  dealCars={cars}
                  enableInsurance={currentStep === 311}
                />
              )}
              {currentStep === 4 && !isCreditDeal && (
                <DealCreatePayment
                  onSubmit={onSubmitStep4}
                  deal={deal}
                  documents={documents}
                  update={update}
                  backHandler={() => back(311)}
                  cars={cars}
                />
              )}
              {currentStep === 4 && isCreditDeal && (
                <DealCreateCreditAppeal
                  onSubmit={handleCreateCreditSubmit}
                  deal={deal}
                  documents={documents}
                  orders={orders}
                  agreementModal={agreementModal}
                  setAgreementModal={setAgreementModal}
                  backHandler={() => back(311)}
                  sendFormLoading={sendFormLoading}
                  creditAppeals={creditAppeals}
                />
              )}

              {currentStep === 5 && !isCreditDeal && (
                <DealCreateDocs
                  onSubmit={onSubmitStep4}
                  deal={deal}
                  documents={documents}
                  update={update}
                  backHandler={back}
                  cars={cars}
                />
              )}
              {currentStep === 6 && !isCreditDeal && (
                <DealCreateOther
                  onSubmit={onSubmitStep5}
                  deal={deal}
                  update={update}
                  documents={documents}
                  backHandler={back}
                  cars={cars}
                />
              )}
              {currentStep === 5 && isCreditDeal && (
                <DealCreateBanks deal={deal} onSubmit={onBankSelect} backHandler={back} services={services}/>
              )}
              {/* {currentStep === 6 && isCreditDeal && (
                <DealCreateBanksPay deal={deal} onSubmit={dealComplete} documents={documents} backHandler={back} />
              )} */}
              {currentStep === 6 && isCreditDeal && (
                <DealCreateCreditDocuments
                  deal={deal}
                  update={update}
                  onSubmit={dealComplete}
                  documents={documents}
                  backHandler={back}
                  chosenBankAppealId={chosenBankAppealId}
                />
              )}
              {currentStep === 7 && isCreditDeal && (
                <DealCreatePledgeRegistration
                  deal={deal}
                  update={update}
                  onSubmit={dealComplete}
                  documents={documents}
                  backHandler={back}
                />
              )}
              {chosenBankId && currentStep === DEAL_STEPS.CLIENT_PAYMENT_APPROVE && (
                <ClientPaymentApprove onSubmit={onClientPaymentApproveSubmit} />
              )}
              {chosenBankId && currentStep === DEAL_STEPS.CLIENT_REGISTRATION_APPROVE && (
                <ClientRegistrationApprove onSubmit={onClientRegistrationApproveSubmit} />
              )}
              {chosenBankId === BANKS.HALYK && (
                <HalykFlow
                  deal={deal}
                  documents={documents}
                  step={currentStep}
                  backHandler={back}
                  update={update}
                  cars={cars}
                  chosenBankAppealId={chosenBankAppealId}
                  bankId={chosenBankId}
                />
              )}
              {chosenBankId === BANKS.ALTYN && (
                <AltynFlow
                  deal={deal}
                  documents={documents}
                  step={currentStep}
                  backHandler={back}
                  update={update}
                  cars={cars}
                  chosenBankAppealId={chosenBankAppealId}
                  bankId={chosenBankId}
                  setLoading={setLoading}
                />
              )}
              {chosenBankId === BANKS.FREEDOM && (
                <FreedomFlow
                  deal={deal}
                  documents={documents}
                  step={currentStep}
                  backHandler={back}
                  update={update}
                  chosenBankAppealId={chosenBankAppealId}
                  cars={cars}
                  bankId={chosenBankId}
                />
              )}
              {chosenBankId === BANKS.FORTE && (
                <ForteFlow
                  deal={deal}
                  documents={documents}
                  step={currentStep}
                  backHandler={back}
                  update={update}
                  chosenBankAppealId={chosenBankAppealId}
                  cars={cars}
                  bankId={chosenBankId}
                />
              )}
              {chosenBankId === BANKS.BEREKE && (
                <BerekeFlow
                  deal={deal}
                  documents={documents}
                  step={currentStep}
                  backHandler={back}
                  update={update}
                  chosenBankAppealId={chosenBankAppealId}
                  cars={cars}
                  bankId={chosenBankId}
                />
              )}
              {chosenBankId === BANKS.BCK && (
                <BCCFlow
                  deal={deal}
                  documents={documents}
                  step={currentStep}
                  backHandler={back}
                  update={update}
                  chosenBankAppealId={chosenBankAppealId}
                  cars={cars}
                  bankId={chosenBankId}
                />
              )}
              {chosenBankId === BANKS.EURAZ && (
                <EurazFlow
                  deal={deal}
                  documents={documents}
                  step={currentStep}
                  backHandler={back}
                  update={update}
                  chosenBankAppealId={chosenBankAppealId}
                  cars={cars}
                  bankId={chosenBankId}
                />
              )}
            </>
          )}
        </div>
        <DealCreationSteps
          currentStep={currentStep}
          setCurrentStep={updateCurrentStep}
          state={state}
          sum={sum}
          deal={savedDeal}
          equipments={equipments}
          services={services}
          cars={cars}
          removeEquipment={removeEquipment}
          removeService={removeService}
          documents={documents}
          chosenBankId={chosenBankId}
          update={update}
          chosenBankAppealId={chosenBankAppealId}
          chosenBankAppeal={chosenBankAppeal}
        />
      </div>

      {succes.message && <Alert message={succes.message} onClick={setSucces} />}
    </>
  );
};

export default DealCreate;
